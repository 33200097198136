import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Location } from '@angular/common';


@Component({
  selector: "back-button",
  templateUrl: "./back-button.component.html",
  styleUrls: ["./back-button.component.scss"],
})
export class BackButtonComponent implements OnInit {
  @Input() label: string = 'Torna Indietro';
  @Output() clicked = new EventEmitter();

  constructor(private location: Location) {}

  ngOnInit(): void {

  }
  goBack() {
    this.location.back();
  }
}
