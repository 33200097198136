import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "src/app/pages/auth/services/auth.service";
import {environment} from 'src/environments/environment';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss", "./navbar.component.print.scss"],
})
export class NavbarComponent implements OnInit {
  isProduction: boolean = false;
  isAdmin: boolean = false;
  isManager: boolean = false;

  constructor(public auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.isProduction = environment.production;
    this.isAdmin = this.auth.isAdmin();
    this.isManager = this.auth.isManager();
  }

  logout() {
    this.auth.logout();
    this.router.navigate(["auth", "login"]);
  }
}
