import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./pages/auth/auth.guard";
import { Page404Component } from "./pages/page404/page404.component";

const routes: Routes = [
  { path: "",
    redirectTo: "dashboard",
    pathMatch: "full" },
  {
    path: "auth",
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "teams",
    loadChildren: () =>
      import("./pages/teams/teams.module").then((m) => m.TeamsModule),
  },
  {
    path: "sessions",
    loadChildren: () =>
      import("./pages/sessions/sessions.module").then((m) => m.SessionsModule),
  },
  {
    path: "players",
    loadChildren: () =>
      import("./pages/players/players.module").then((m) => m.PlayersModule),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "clubs",
    loadChildren: () =>
      import("./pages/clubs/clubs.module").then((m) => m.ClubsModule),
  },
  {
    path: "trackers",
    loadChildren: () =>
      import("./pages/trackers/trackers.module").then((m) => m.TrackersModule),
  },
  { path: "**", component: Page404Component },
];

@NgModule({
  declarations: [Page404Component],
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
